/* eslint-disable */
import {
  bus
} from '@/main';
const $ = window.jQuery = require('jquery');  
export default {
  name: 'user-agreement',
  components: {},
  props: [],
  data () {
    return {

    }
  },
  computed: {

  },
  mounted () {
    bus.$emit('showHeaderbtn', false);
    bus.$emit('showHeaderSection', true);
  },
  methods: {
  },
  beforeCreate: function() {
    $('body').addClass('dashboard')
    $('body').addClass('public_page_for_wrp_img')
  },
  beforeRouteLeave: function (to, from, next) {
    $('body').removeClass('dashboard')
    $('body').removeClass('public_page_for_wrp_img')
    $(window).scrollTop(0);
    next();
  },
}